import React from 'react';
import './Agreement.css'
import Header from './Header';
import './AdaptiveStyles.css'
import Navigatorr from './Navigatorr';

const Agreement = () => {

    return (
    <><Header/>
        <div className="agreement">
            
                <main className="agreement-content">
                    <div className="container">
                        <h1>Пользовательское соглашение</h1>
                        <div >
                        <h2>1. Общие положения</h2>
                        <p>1.1. Настоящее Пользовательское соглашение (далее – "Соглашение") регулирует отношения между владельцем сайта (далее – "Администрация") и пользователем сети Интернет (далее – "Пользователь") по использованию веб-сайта https://nyuroprint.ru (далее – "Сервис").</p>
                        <p>1.2. Используя Сервис, Пользователь соглашается с условиями данного Соглашения. Если Пользователь не согласен с каким-либо пунктом Соглашения, он обязан прекратить использование Сервиса.</p>

                        <h2>2. Описание Сервиса</h2>
                        <p>2.1. Сервис предназначен для поиска дефектов 3D печати по загруженным Пользователем фотографиям.</p>
                        <p>2.2. Сервис включает в себя страницу для загрузки фотографий и ввода параметров принтера, а также форму обратной связи.</p>

                        <h2>3. Права и обязанности Пользователя</h2>
                        <p>3.1. Пользователь имеет право использовать Сервис в соответствии с условиями настоящего Соглашения.</p>
                        <p>3.2. При использовании формы обратной связи Пользователь обязуется предоставлять достоверную информацию о себе.</p>
                        <p>3.3. Пользователь обязуется не использовать Сервис для загрузки материалов, нарушающих законодательство или права третьих лиц.</p>

                        <h2>4. Ограничение ответственности</h2>
                        <p>4.1. Администрация не несет ответственности за точность результатов анализа дефектов 3D печати, предоставляемых Сервисом.</p>
                        <p>4.2. Администрация не несет ответственности за любой ущерб, возникший в результате использования или невозможности использования Сервиса.</p>
                        <p>4.3. Администрация не несет ответственности за содержание и достоверность информации, предоставляемой Пользователем при использовании Сервиса.</p>

                        <h2>5. Обработка персональных данных</h2>
                        <p>5.1. Пользователь дает согласие на обработку своих персональных данных, предоставленных при использовании формы обратной связи, в соответствии с действующим законодательством о защите персональных данных.</p>

                        <h2>6. Изменение условий Соглашения</h2>
                        <p>6.1. Администрация оставляет за собой право изменять условия настоящего Соглашения в одностороннем порядке без предварительного уведомления Пользователя.</p>

                        <h2>7. Заключительные положения</h2>
                        <p>7.1. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.</p>
                        <p>7.2. Все споры, возникающие в связи с использованием Сервиса, разрешаются путем переговоров. В случае невозможности достижения согласия, споры подлежат рассмотрению в суде по месту нахождения Администрации.</p>

                        <p>Используя Сервис, вы подтверждаете, что прочитали, поняли и согласны с условиями настоящего Соглашения.</p>
                        </div>
                    </div>
                    
                </main> 
        </div><Navigatorr/>
    </>
    );
};

export default Agreement;